@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* All fonts are imported here */

html, body, #root{
  height: 100%;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: 'Raleway';
}

.container{
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.botonDefault1{
  width: 130px;
  height: 40px;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  border: 2px solid #7879f1;
  background-color: #7879f1;
}

.botonDefault2{
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border: 2px solid #7879f1;
  background-color: #7879f1;
}

.HomePageGlobals {
  margin-top: 25px;
}

.HomeMainImage {
  margin: auto;
  width: 100%;
}

.HomPageText {
  text-align: center;
  font-weight: 400;
}

.HomPageOportunidadesText{
  text-align: center;
  font-weight: 600;
  margin-top: 100px;
}

.HomeSearchButton {
  margin: auto;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 75vw;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  cursor: pointer;
  margin-bottom: 35px;
}

.HomeSearchText{
    width: 100%;
    margin: 0;
    text-align: center;
}

.HomeSearchIcon{
    margin: 5px;
    height: 35px;
}

.SocialWidgetGlobals {
  margin: 15px;
  margin-top: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.SocialWidgetIcon {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.CardRow {
  margin-top: 35px;
  margin-left: 10px;
  margin-right: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.Card {
  padding: 10px;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
}
.CardBody {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-size: 10px;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  text-transform: capitalize;
}

.BotonMasInfo {
  width: 130px;
  height: 40px;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  border: 2px solid #7879f1;
  background-color: #7879f1;
}

.AreaQuienesSomos {
  margin: 15px;
}
.TituloQuienesSomos {
  font-weight: bold;
  text-align: end;
  padding-right: 25px;
  margin-bottom: 15px;
}
.ImagenQuienesSomos {
  padding: 10px;
}

.AreaNuestrosServicios {
  margin: 15px;
  margin-top: 30px;
}

.TituloNuestrosServicios {
  font-weight: bold;
  text-align: start;
  margin-bottom: 5px;
  padding-left: 35px;
}

.ImagenNuestrosServicios {
  padding: 25px;
}

.NavBar {
    margin: 0;
    padding: 0;
    background-color: white !important;
    font-weight: 600;
    padding-left: 2vw;
    padding-right: 2vw;
    box-shadow: 0px 8px 8px -6px rgba(0,0,0,.5);
}

.NavBar-NavBarBrand {
    width: 20%;
    cursor: pointer;
}

.NavBar-Links {
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}


.NavBar-Links-Link {
    margin: 20px;
}

.NavBar-Widgets {
    width: 20%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:flex-end;
            justify-content:flex-end;
}
.LoginWidgetAlign {
    margin-top: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    cursor: pointer;
}
.ItemListHeader{
    background-color: darkgray;
    padding-bottom: 25px;
}
* {
  font-size: 16px;
}
.ItemRowContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.EstiloTexto {
  text-transform: capitalize;
  font-family: 'Raleway';
  font-size: 14px;
  text-align: start;
}

.botonDefault2 {
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border: 2px solid #7879f1;
  background-color: #7879f1;
}

.RefIcon{
    margin-right: 10px;
}
.SearchBarBody {
  margin: 0;
  padding: 30px;
  background-color: #e5e5e5;
}

.SearchBarText {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.SearchBarBotonera {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.SearchBarCol {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.SearchBarColTitle {
  margin-bottom: 5px;
}
.BotonBorrarFiltrosContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.BotonBorrarFiltrosContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.BotonBorrarFiltros {
  width: 130px;
  height: 40px;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  border: 2px solid #7879f1;
  background-color: #7879f1;
}

.OhhContainer {
  padding-top: 50px;
}

.TextosOhh {
  padding: 30px;
  font-weight: 500;
  text-align: center;
}

.DestacadoOhh {
  font-weight: bold;
  text-align: center;
}

.AreaOhh {
}

.ImagenOhh {
  padding: 10px;
}


.ItemBody{
    margin: 10px;
}

.CardBody{
    margin: 15px;
}

.BodyImage {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.TitleTextPill{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}

.BodyTextRow{
    /* column-gap: 5px; */
}

.BodyTextPill{
    margin-left: 2px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}

.MapPill{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    min-height: 300px;    
}

.MapPill div:first-child {
    position: relative !important;
    min-height: 30px;
    width: 100%;
    border-radius: 5px;
}


.Map{
    border-radius: 5px;
}

.NotaTextPill{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 8px;
}

.SignInContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.LoginFormGroup{
    margin: 10px;
}
.LoginButtonDiv{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin: 15px;
}

.RegisterButtonDiv{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 15px;
}

.botonDefault1{
    width: 130px;
    height: 40px;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
    border-radius: 30px;
    border: 2px solid #7879f1;
    background-color: #7879f1;
  }
.FooterGlobals {
  margin-top: 50px;
  /* position: fixed;
    bottom: 0px; */
}

.FooterLinks {
  padding-left: 25px;
  padding-right: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.FooterLinksRow {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 10px;
}

.FooterLogo {
  max-height: 100px;
  text-align: center;
}

.FooterCenter {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.FooterForm {
  padding-right: 25px;
  padding-left: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.socialArrows {
  width: 22px;
  height: 22px;
}

.AddToOrderBody {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.AddToOrderTitleContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.AddToOrderCounterContainer {
  margin-left: 20px;
  margin-right: 20px;
}
.AddToOrderButtonContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.AddToOrderTitle {
  font-weight: 600;
  font-size: 18px;
}

.CounterTitle {
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.CounterBlock{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.ItemBody{
    margin: 10px;
}

.CardBody{
    margin: 15px;
}

.BodyImage {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.TitleTextPill{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}

.BodyTextRow{
    /* column-gap: 5px; */
}

.BodyTextPill{
    margin-left: 2px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}

.MapPill{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    min-height: 300px;    
}

.MapPill div:first-child {
    position: relative !important;
    min-height: 30px;
    width: 100%;
    border-radius: 5px;
}


.Map{
    border-radius: 5px;
}

.NotaTextPill{
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 8px;
}
.Background-component {
  /* The image used */
  background-image: url(/static/media/background_map.7c67cedb.png);

  /* Full height */
  max-width: 100%;
  min-height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.TextoIdeas {
  padding: 5%;
  font-weight: 500;
  text-align: center;
}

.TarjetasIdeas {
  margin: 10px;
}

.TarjetaIdeas {
  padding: 15px;
}

.TituloTarjetaIdea {
  padding-bottom: 5px;
  text-align: center;
  font-weight: bold;
}

.BajadaTarjetaIdea {
  padding: 15px;
  text-align: center;
}

.ImagenTarjetaIdea {
}

.OrderButtonBar{
    margin-left: 50px;
    margin-right: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.OrderButton{
    border: 2px solid #7879f1;
    background-color: #7879f1;
  }

  .OrderButtons{
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .textoModal{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .imagenModal{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 10px;
  }
.OrderListContainer {
  margin: 5%;
}

.OrderTitleBar {
  font-weight: 600;
}

.OrderTitleRow {
  background-color: darkgray;
}

.BotonQuitar {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.OrderContainerHeader{
    margin: 50px;
    margin-bottom: 10px;
}
.ResumeCardBody{
    margin: 50px;
    padding: 20px;
}

.ResumeTextPill{
    padding: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-left: 15px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 600;
}
