.AddToOrderBody {
  display: flex;
  justify-content: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.AddToOrderTitleContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.AddToOrderCounterContainer {
  margin-left: 20px;
  margin-right: 20px;
}
.AddToOrderButtonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.AddToOrderTitle {
  font-weight: 600;
  font-size: 18px;
}

.CounterTitle {
  font-weight: 600;
  text-align: center;
  margin: 0;
}
