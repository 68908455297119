.Card {
  padding: 10px;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
}
.CardBody {
  display: flex;
  align-items: flex-end;
  font-size: 10px;
  column-gap: 5px;
  text-transform: capitalize;
}

.BotonMasInfo {
  width: 130px;
  height: 40px;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  border: 2px solid #7879f1;
  background-color: #7879f1;
}
