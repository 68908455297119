.OrderListContainer {
  margin: 5%;
}

.OrderTitleBar {
  font-weight: 600;
}

.OrderTitleRow {
  background-color: darkgray;
}

.BotonQuitar {
  display: flex;
  justify-content: flex-end;
}
