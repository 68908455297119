/* All fonts are imported here */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body, #root{
  height: 100%;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: 'Raleway';
}

.container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.botonDefault1{
  width: 130px;
  height: 40px;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border-radius: 30px;
  border: 2px solid #7879f1;
  background-color: #7879f1;
}

.botonDefault2{
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.11);
  border: 2px solid #7879f1;
  background-color: #7879f1;
}
