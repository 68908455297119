.SocialWidgetGlobals {
  margin: 15px;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.SocialWidgetIcon {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
