.OhhContainer {
  padding-top: 50px;
}

.TextosOhh {
  padding: 30px;
  font-weight: 500;
  text-align: center;
}

.DestacadoOhh {
  font-weight: bold;
  text-align: center;
}

.AreaOhh {
}

.ImagenOhh {
  padding: 10px;
}
