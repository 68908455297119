.FooterGlobals {
  margin-top: 50px;
  /* position: fixed;
    bottom: 0px; */
}

.FooterLinks {
  padding-left: 25px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.FooterLinksRow {
  display: flex;
  margin-bottom: 10px;
}

.FooterLogo {
  max-height: 100px;
  text-align: center;
}

.FooterCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FooterForm {
  padding-right: 25px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.socialArrows {
  width: 22px;
  height: 22px;
}
