.Background-component {
  /* The image used */
  background-image: url(../assets/img/background_map.png);

  /* Full height */
  max-width: 100%;
  min-height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.TextoIdeas {
  padding: 5%;
  font-weight: 500;
  text-align: center;
}

.TarjetasIdeas {
  margin: 10px;
}

.TarjetaIdeas {
  padding: 15px;
}

.TituloTarjetaIdea {
  padding-bottom: 5px;
  text-align: center;
  font-weight: bold;
}

.BajadaTarjetaIdea {
  padding: 15px;
  text-align: center;
}

.ImagenTarjetaIdea {
}
